import { HttpClient, HttpContext, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable, Type, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { SKIP_AUTH } from '@chapo/auth-data';
import { Eventable, Paginated, TableQueryParams, createEmptyPaginatedResponse, prepareQueryParams } from '@chapo/shared-utils';
import {
  QueryObserverResult,
  createSuccessObserverResult,
  filterSuccessResult,
  injectMutation,
  injectQuery,
  injectQueryClient,
  toPromise,
} from '@ngneat/query';
import { Result } from '@ngneat/query/lib/types';
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { saveAs } from 'file-saver-es';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { Observable, debounceTime, filter, map, of, switchMap, throwError } from 'rxjs';
import { NewCompanyForm, UpdateCompanyForm } from '../data-access/company-form.type';
import { Company } from '../data-access/company.type';

export function injectSearchableList<
  T,
  TService extends { getAll: (queryParams: { search: string }) => Result<QueryObserverResult<Paginated<T>, Error>> },
>(options: { service: Type<TService>; queryParams$: Observable<{ search: string }> }) {
  const service = inject(options.service);

  const result$ = options.queryParams$.pipe(
    debounceTime(500),
    switchMap((queryParams) => {
      return service.getAll(queryParams).result$;
    }),
  );

  const result = toSignal(result$, {
    initialValue: createSuccessObserverResult(createEmptyPaginatedResponse<T>()),
  });

  const list$ = result$.pipe(
    filterSuccessResult(),
    map((query) => query.data),
  );
  const list = toSignal(list$, { initialValue: createEmptyPaginatedResponse<T>() });

  return { result, result$, list, list$ };
}

interface CompaniesState {}

@Injectable({
  providedIn: 'root',
})
export class CompaniesService extends ComponentStore<CompaniesState> {
  private http = inject(HttpClient);
  private queryClient = injectQueryClient();
  private query = injectQuery();
  private mutation = injectMutation();

  constructor() {
    super({});
  }

  getAll(queryParams: TableQueryParams) {
    const endpoint = `/backend/api/companies`;
    const queryString = prepareQueryParams(queryParams);

    return this.query({
      queryKey: ['companies', queryParams] as const,
      queryFn: ({ signal }) => {
        const source = this.http.get<Paginated<Company>>(
          queryString.length > 0 ? `${endpoint}?${queryString}` : endpoint,
        );

        return toPromise({ source, signal });
      },
    });
  }

  getById(companyId: number) {
    return this.query({
      queryKey: ['companies', companyId] as const,
      queryFn: ({ signal }) => {
        const source = this.http.get<Company>(`/backend/api/companies/${companyId}`);

        return toPromise({ source, signal });
      },
    });
  }

  createCompany() {
    return this.mutation({
      mutationFn: ({ model }: { model: NewCompanyForm }) => {
        return this.http.post(`/backend/api/companies`, model);
      },
      onSuccess: () => {
        this.queryClient.invalidateQueries({ queryKey: ['companies'] });
      },
    });
  }

  updateCompany() {
    return this.mutation({
      mutationFn: ({ model }: { model: UpdateCompanyForm }) => {
        return this.http.put(`/backend/api/companies/${model.id}`, model);
      },
      onSuccess: () => {
        this.queryClient.invalidateQueries({ queryKey: ['companies'] });
      },
    });
  }

  uploadFiles() {
    return this.mutation({
      mutationFn: ({ companyId, files }: { companyId: number; files: NzUploadFile[] }) => {
        const formData = new FormData();

        files.forEach((file) => {
          const originFileObj = file.originFileObj ?? file;
          const name = originFileObj.name ?? file.name;

          formData.append('files[][file]', originFileObj as File);
          formData.append('files[][name]', name);
        });

        const request = new HttpRequest('POST', `/backend/api/companies/${companyId}/add_files`, formData, {
          reportProgress: true,
        });

        return this.http.request(request).pipe(
          filter((e) => e instanceof HttpResponse),
          switchMap((response) => {
            if (response instanceof HttpResponse) {
              const body = (response as HttpResponse<{ errors: string[] }>).body ?? { errors: [] };

              return of(body);
            }

            return throwError(() => response);
          }),
        );
      },
      onSuccess: () => {
        this.queryClient.invalidateQueries({ queryKey: ['companies'] });
      },
    });
  }

  deleteFile() {
    return this.mutation({
      mutationFn: ({ companyId, fileId }: { companyId: number; fileId: number }) => {
        return this.http.delete(`/backend/api/companies/${companyId}/destroy_file/${fileId}`);
      },
      onSuccess: () => {
        this.queryClient.invalidateQueries({ queryKey: ['companies'] });
      },
    });
  }

  downloadFile() {
    return this.mutation({
      mutationFn: ({ companyId, fileId }: { companyId: number; fileId: number }) => {
        return this.http
          .get<{ id: number; name: string; url: string }>(`/backend/api/companies/${companyId}/download_file/${fileId}`)
          .pipe(
            switchMap((response) => {
              return this.http
                .get(response.url, { responseType: 'blob', context: new HttpContext().set(SKIP_AUTH, true) })
                .pipe(
                  tapResponse({
                    next: (data) => {
                      saveAs(data, response.name);
                    },
                    error: () => {},
                  }),
                );
            }),
          );
      },
      onSuccess: () => {
        // this.queryClient.invalidateQueries({ queryKey: ['companies'] });
      },
    });
  }

  getFileHistoryById(params: { companyId: number; fileId: number }) {
    return this.query({
      queryKey: ['companies', params.companyId, 'file_history', params.fileId] as const,
      queryFn: ({ signal }) => {
        const source = this.http.get<Eventable[]>(
          `/backend/api/companies/${params.companyId}/file_history/${params.fileId}`,
        );

        return toPromise({ source, signal });
      },
    });
  }
}
