import { HttpContextToken, HttpErrorResponse, HttpHandlerFn, HttpRequest, HttpStatusCode } from '@angular/common/http';
import { inject } from '@angular/core';
import { catchError, throwError } from 'rxjs';
import { AuthService } from '../services/auth.service';

export const SKIP_AUTH = new HttpContextToken<boolean>(() => false);

export function authInterceptor(req: HttpRequest<unknown>, next: HttpHandlerFn) {
  if (req.context.get(SKIP_AUTH)) {
    return next(req);
  }

  const authService = inject(AuthService);
  const jwt = authService.state().jwt ?? '';

  const clonedRequest = req.clone({
    setHeaders: {
      Authorization: jwt ? `Bearer ${jwt}` : '',
    },
  });

  return next(clonedRequest).pipe(
    catchError((response) => {
      if (response instanceof HttpErrorResponse) {
        switch (response.status) {
          case HttpStatusCode.Unauthorized:
            authService.logoutFromInterceptor();
        }
      }

      return throwError(() => response);
    }),
  );
}
